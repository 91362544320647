.container {
  background-image: url('https://media.oneturf.news/media/attachments/1743491156675_wordleMwebWidget.png');
  background-position: center;
  cursor: pointer;
  background-size: cover;
  background-repeat: no-repeat;
  margin-bottom: 16px;
  position: relative;
  border-radius: 12px;
  height: 100px;
  width: 100%;
  margin-left: 2px;
}

@media screen and (min-width: 768px) {
  .container {
    background-image: url('https://media.oneturf.news/media/attachments/1741160053718_wordleWidget.png');
    height: 255px;
    width: 304px;
    margin-bottom: 30px;
  }
}
