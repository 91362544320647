@import "@assets/scss/variables.scss";
@import "@assets/scss/mixins.scss";

.downloadBanner {
    background-image: url(../../../../assets/images/Banner.png);
    width: 100%;
    height: 241px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 12px;
    margin-bottom: 24px;
    cursor: pointer;
    [data-light-mode="false"] & {
        background-image: url(../../../../assets/images/darkBanner.png) !important;
    }
}


@media (min-width: 992px) and (max-width: 1400px) {
    .homeContainer {
        width: 1330px;
        max-width: 100%;
        .centerContent {
            width: calc(100% - 640px);
        }
        .commonSidebar {
            width: 320px;
        }
       
       
    }
}

@media (min-width: 1280px) and (max-width: 1400px) {
    .homeContainer {
        .centerContent {
            width: calc(100% - 668px);
        }
        .commonSidebar {
            width: 334px;
        }
    }
}
