@import '@assets/scss/variables.scss';
@import '@assets/scss/mixins.scss';

.h4MarginBtm {
  margin-bottom: 16px !important;
  line-height: 1.2 !important;
}

.homeArticles {

  &:last-child {
    margin-bottom: 0;
  }

  .boxBtmPad {
    margin-bottom: 16px;

    .tagMargin {
      margin: 0 !important;
    }
  }

  .seriesInfo {
    p {
      color: $font-light;
      font-size: 13px;
      line-height: 17px;
    }
  }

  .btnList {
    margin-bottom: 16px;

    a {
      margin-right: 20px;
      font-size: 14px;
      line-height: 20px;
      color: var(--font-dark);
      font-weight: 700;

      &:hover {
        color: var(--theme-color-light);
        border-color: var(--theme-color-light);
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

// .headingTag {
//   margin: 0px !important;
// }

.articleGridList {
  article {
    min-height: calc(100% - 16px);
  }
}

.articleGroup {
  padding: 11px 3px;

  :nth-last-child(1),
  :nth-last-child(2) {
    article {
      margin-bottom: 0;
    }
  }
}

@media (max-width: 1199px) {
  .homeArticles {
    .btnList {
      a {
        margin-right: 1rem;
        margin-inline-start: 0.25rem;
      }
    }
  }
}

@media (max-width: 575px) {
  .homeArticles {
    .seriesInfo {
      p {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }

  .articleGroup {
    padding: 11px 12px;

    :nth-last-child(2) {
      article {
        margin-bottom: 8px;
      }
    }
  }
}